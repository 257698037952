import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService } from './user/auth-guard.service';
import { AssignOrderComponent } from './components/+assign-order/assign-order.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MaintenanceGuard } from './services/maintenance.guard';
import { DownloadsComponent } from './modules/downloads/downloads.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ContactComponent } from './modules/contact/contact.component';

export const routes: Routes = [
    {
        path: 'isfp',
        loadChildren: () => import('./modules/+isfp/isfp.module').then((m) => m.IsfpModule),
    },
    {
        path: 'admin',
        canActivate: [AuthGuardService, MaintenanceGuard],
        loadChildren: () => import('./modules/+admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'assign/:leadId',
        canActivate: [MaintenanceGuard],
        component: AssignOrderComponent,
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuardService, MaintenanceGuard],
        loadChildren: () => import('./modules/+dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: '',
        canActivate: [MaintenanceGuard],
        loadChildren: () => import('./modules/+questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule),
    },
    {
        path: 'contact',
        canActivate: [AuthGuardService, MaintenanceGuard],
        component: ContactComponent,
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        pathMatch: 'full',
    },
    {
        path: 'downloads',
        component: DownloadsComponent,
        pathMatch: 'full',
    },
    { path: '**', pathMatch: 'full', component: NotfoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top', // scroll to top when navigating
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
