import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { User } from './user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient, private auth: AuthService) {}

    isLoggedIn(): Observable<boolean> {
        return this.getLoggedInUser().pipe(
            catchError((err) => {
                if (err.status === 401) {
                    // 401 means user is not logged in
                    return of(false);
                }
                return throwError(err);
            }),
            map((user) => {
                if (user) {
                    return true;
                }
                return false;
            })
        );
    }

    getLoggedInUser(...sections: string[]): Observable<User> {
        // special use case: we want to get user details if possible, but authentication is not required.
        // so if not logged in, do not redirect the user to IAM.
        // if a valid token is available, use that.
        // if not, try to get a CSRF token with 3rd party cookie.
        if (this.auth.hasValidCsrfToken()) {
            return this.getUser(this.auth.getCsrfToken(), sections);
        } else {
            // try to fetch new csrf token.
            // this might throw error if user is not logged in or has 3rd party cookies disabled.
            return this.auth.getCsrfTokenRequest().pipe(switchMap(({ token }) => this.getUser(token, sections)));
        }
    }

    getUser(token: string, sections?: string[]) {
        let params: HttpParams;
        if (sections) {
            params = new HttpParams();
            sections.forEach((section) => {
                params = params.append('sections', section);
            });
        }
        return this.http.get<User>(environment.auth.usersUrl, {
            params,
            headers: {
                Authorization: `CSRF ${token}`,
            },
        });
    }
}
