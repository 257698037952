import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../user/auth.service';
import { catchError, switchMap } from 'rxjs/operators';

/**
 * Base class for api services - covering path transformations and handling request/response staff.
 * Extensible by specialized api services - having configured base url and paths related methods
 *
 * @example
 *
 * ```ts
 * // in exmaple.service.ts
 * import { Injectable } from '@angular/core';
 * import { ApiBase } from '../api';
 *
 * @Injectable()
 * export class ExampleApiService extends ApiBase {
 *
 *   protected baseUrl: string = '/test';
 *
 *   get(data: any) {
 *     return this.request('post', '/something/{id}', data);
 *   }
 * }
 */
@Injectable()
export class ApiBase {
    protected baseUrl: string;

    private httpBackendClient: HttpClient; // for performing requests without authorization context

    constructor(
        protected http: HttpClient,
        protected translate: TranslateService,
        handler: HttpBackend,
        protected auth: AuthService
    ) {
        this.httpBackendClient = new HttpClient(handler);
    }

    /**
     * Request wrapper
     */
    request(
        method: string,
        url: string,
        data?: any,
        options?: any,
        includeAuthContextIfLoggedIn = false
    ): Observable<any> {
        const body = data && Object.assign({}, data);
        const path = this.baseUrl + this.path(url, body);

        if (includeAuthContextIfLoggedIn) {
            // try performing a csrf request
            return this.csrfRequest(method, path, body, options).pipe(
                catchError((error) => {
                    // perform normal request without authorization context
                    if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
                        return this.httpBackendClient.request(method, path, { body, ...options });
                    }
                    return throwError(error);
                })
            );
        }
        return this.http.request(method, path, { body, ...options });
    }

    /**
     * Perform an authenticated request with CSRF token
     */
    csrfRequest(method: string, path: string, body: any, options?: any) {
        const doRequest = (token: string) => {
            return this.httpBackendClient.request(method, path, {
                body,
                ...options,
                headers: {
                    Authorization: `CSRF ${token}`,
                },
            });
        };
        if (this.auth.hasValidCsrfToken()) {
            const token = this.auth.getCsrfToken();
            return doRequest(token);
        }
        return this.auth.getCsrfTokenRequest().pipe(switchMap(({ token }) => doRequest(token)));
    }

    /**
     * Path transformer - replaces {some}/{patterns} within data.patterns and data.some properties
     *
     * @note Path properties are removed from data
     */
    protected path(url: string, data?: any, remove: boolean = true): string {
        const params = url.match(/{\w+}/g);

        if (params && !data) {
            throw Error('Data required for path ' + url);
        }

        (params || []).forEach((param) => {
            const property = param.substring(1, param.length - 1);
            let value = data[property];

            if (value === undefined) {
                console.warn('Property ' + property + ' missing for path ' + url); // tslint:disable-line no-console
                value = '';
            }
            url = url.replace(param, value);
            if (remove) {
                delete data[property];
            }
        });
        return url;
    }
}
