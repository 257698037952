import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Attachment, Mandate, MandateState, MandateSubState, MandateType } from './mandate.model';
import { catchError, map } from 'rxjs/operators';
import {
    BuildingObject,
    SalesforceLead,
    SalesforceMandate,
    SalesforceMandateDetail,
    SalesforceOpportunity,
} from '../salesforce/salesforce.model';
import { environment } from '../../environments/environment';
import { ApiAuthenticated } from '../api/api.authenticated';

@Injectable({
    providedIn: 'root',
})
export class MandateService extends ApiAuthenticated {
    protected baseUrl = environment.configit.url;

    assignMandate(leadId: string, confirmationKey: string) {
        const url = `/orders/${leadId}`;
        const payload = {
            confirmationKey,
        };
        return this.request('put', url, payload);
    }

    updateOrder(orderId: string, payload: any) {
        const url = `/orders/${orderId}`;
        return this.request('patch', url, payload, {
            observe: 'response',
        });
    }

    getMandates(): Observable<Mandate[]> {
        return this.request('get', `/orders`).pipe(
            map((mandates: SalesforceMandate[]) =>
                mandates.filter((mandate) => mandate.order).map((mandate) => this.transformMandate(mandate))
            )
        );
    }

    getMandate(orderId: string): Observable<Mandate> {
        return this.request('get', `/orders/${orderId}`).pipe(
            map(
                (mandate: SalesforceMandateDetail) => this.transformMandateDetail(mandate),
                catchError(() => {
                    return of(undefined);
                })
            )
        );
    }

    createCase(orderId: string, category: string, subCategory: string, subject: string, description: string) {
        const url = `/orders/${orderId}/cases`;
        return this.request(
            'post',
            url,
            {
                category: category,
                subCategory: subCategory,
                subject: subject,
                description: description,
            },
            {
                observe: 'response',
            }
        );
    }

    getAttachments(orderId: string, attachmentType?: string): Observable<Attachment[]> {
        const query = attachmentType ? `?type=${attachmentType}` : '';
        return this.request('get', `/orders/${orderId}/attachments${query}`);
    }

    deleteAttachment(orderId: string, attachmentId: string): Observable<boolean> {
        return this.request('delete', `/orders/${orderId}/attachments/${attachmentId}`).pipe(map(() => true));
    }

    transformMandateDetail(mandate: SalesforceMandateDetail): Mandate {
        let rawData;
        try {
            rawData = JSON.parse(mandate.RawData__c);
        } catch (error) {
            // ignore, rawData will be empty
        }

        if (mandate.attributes.type === 'Lead') {
            const m = <SalesforceLead>mandate;
            return {
                id: m.Id,
                confirmationKey: m.SubsidyMandateConfirmationKey__c,
                type: MandateType.Lead,
                personalData: {
                    firstName: m.FirstName,
                    lastName: m.LastName,
                    name: m.Name,
                    city: m.Address.city,
                    street: m.Address.street,
                    postalCode: m.Address.postalCode,
                },
                rawData,
                typeOfSubsidy: m.TypeOfSubsidy__c,
                closeDate: m.CloseDate,
                mandateNo: mandate.LeadId__c,
                state: m.AutoConversion__c && !m.IsConverted ? MandateState.converting : MandateState.eligible,
                subState: MandateSubState.pending,
                tpbNumber: m.TPBNumber__c,
                tpnNumber: m.TPNNumber__c,
                contractor: m.ContractingTpnTpb__c,
                vitoflowId: m.VitoflowId__c,
                bzaId: m.BzaID__c,
                bndId: m.BnDID__c,
            };
        } else {
            const m = <SalesforceOpportunity>mandate;
            return {
                id: m.Id,
                confirmationKey: m.SubsidyMandateConfirmationKey__c,
                type: MandateType.Opportunity,
                personalData: {
                    firstName: m.Account.FirstName,
                    lastName: m.Account.LastName,
                    name: m.Name,
                    postalCode: !m.BuildingObject
                        ? m.Account.BillingAddress.postalCode
                        : m.BuildingObject.PostalCode__c,
                    city: !m.BuildingObject ? m.Account.BillingAddress.city : m.BuildingObject.City__c,
                    street: !m.BuildingObject ? m.Account.BillingAddress.street : m.BuildingObject.Street__c,
                    houseNumber: m.BuildingObject && m.BuildingObject.HouseNumber__c,
                },
                mandateNo: m.LeadId__c,
                typeOfSubsidy: m.TypeOfSubsidy__c,
                closeDate: m.CloseDate,
                rawData,
                state: this.mapState(m.StageName),
                subState: this.mapSubState(m.SubStageName__c),
                projectClosure: m.CloseDate,
                tasks: m.Tasks,
                handledInAsana: !!m.AsanaLink__c,
                tpbNumber: m.TPBNumber__c,
                tpnNumber: m.TPNNumber__c,
                contractor: m.ContractingTpnTpb__c,
                vitoflowId: m.VitoflowId__c,
                endcustomerIban: m.Account.EndcustomerIBAN__pc,
                bzaId: m.BzaID__c,
                bndId: m.BnDID__c,
            };
        }
    }

    transformMandate(mandate: SalesforceMandate): Mandate {
        return {
            id: mandate.id,
            personalData: {
                firstName: mandate.firstName,
                lastName: mandate.lastName,
                name: mandate.name,
                postalCode: mandate.address && mandate.address.postalCode,
                city: mandate.address && mandate.address.city,
                street: mandate.address && mandate.address.street,
                houseNumber: mandate.address && mandate.address.houseNumber,
            },
            mandateNo: mandate.order,
            closeDate: mandate.CloseDate,
            typeOfSubsidy: mandate.TypeOfSubsidy__c,
            state: this.mapState(mandate.status, mandate.conversionInProgress),
            subState: this.mapSubState(mandate.substatus),
            projectClosure: mandate.deadline,
            openTasks: mandate.openTasks,
            tpbNumber: mandate.TPBNumber__c,
            tpnNumber: mandate.TPNNumber__c,
            contractor: mandate.ContractingTpnTpb__c,
            vitoflowId: mandate.VitoflowId__c,
            bzaId: mandate.BzaID__c,
            bndId: mandate.BnDID__c,
        };
    }

    mapState(state: string, conversionInProgress?: boolean): MandateState {
        if (conversionInProgress) {
            return MandateState.converting;
        }
        switch (state) {
            case 'Mandate confirmed':
                return MandateState.assigned;
            case 'Project release':
                return MandateState.approved;
            case 'Subsidy granted':
                return MandateState.confirmed;
            case 'Documents in review':
                return MandateState.complete;
            case 'Application closed':
                return MandateState.closed;
            case 'Geschlossen und gewonnen':
                return MandateState.closedAndWon;
            default:
                return MandateState.eligible; // state comes from SF Opportunity. in case of a Lead we do not have a state
        }
    }

    mapSubState(subState: string): MandateSubState {
        switch (subState) {
            case 'HANDING_IN_APPLICATION':
                return MandateSubState.handingInApplication;
            case 'WAITING_FOR_DOCUMENT_UPLOAD':
                return MandateSubState.awaitingUpload;
            case 'PROCESSING_APPLICATION':
                return MandateSubState.processingApplication;
            case 'DOCUMENTS_IN_REVIEW':
                return MandateSubState.documentsInReview;
            case 'DOCUMENTS_IN_EXTERNAL_REVIEW':
                return MandateSubState.documentsInExternalReview;
            case 'REQUESTING_PAYOUT':
                return MandateSubState.requestingPayout;
            case 'PAYMENT_REQUESTED':
                return MandateSubState.paymentRequested;
            default:
                return MandateSubState.pending;
        }
    }
}
