import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DateAdapter } from '@angular/material/core';

import { AppConfig } from './app.config';
import { Navigation } from './components/navigation/navigation.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './user/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AuthGuardService } from './user/auth-guard.service';
import { InstanaService } from './services/instana.service';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '../environments/environment';

import { InfoBannerComponent } from './components/info-banner/info-banner.component';

import { formatDate } from '@angular/common';

@Component({
    selector: 'vi-subc-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    menu: Navigation;
    logo: string;

    campaign: string;
    embedded = false;
    currentDate = new Date();
    startDate = new Date('2022-12-01T00:00:00');

    constructor(
        private instana: InstanaService,
        private router: Router,
        private translate: TranslateService,
        private title: Title,
        private meta: Meta,
        private config: AppConfig,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private dateAdapter: DateAdapter<any>,
        private authGuard: AuthGuardService,
        private activatedRoute: ActivatedRoute,
        private auth: AuthService
    ) {
        // translates init
        translate.setDefaultLang('de');
        translate.use('de');
        // set local for usage in datepicker
        this.dateAdapter.setLocale('de');
        // register additional icons
        this.registerIcons(config.icons);
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const { embedded } = params;
            const { campaign } = params;
            if (embedded) {
                this.embedded = true;
                document.body.classList.add('app-embedded');
                if (campaign) {
                    this.campaign = campaign;
                    document.body.classList.add('color-scheme-' + this.campaign);
                }
            }
        });
        this.auth.init();
        // get logo/menu options
        this.config.menu.top.groups
            .find((f) => f.identifier === 'hydrobalance')
            .items.find((f) => f.label === 'my-hydrobalances').url = environment.http.vitoflow + '/project';
        this.menu = this.config.menu;
        this.logo = this.config.logo;
        // and page title/description translated
        const parts = [this.config.pageTitle, this.config.pageDescription];
        this.translate.stream(parts).subscribe((translated: any) => {
            this.title.setTitle(translated[parts[0]]);
            this.meta.addTag({ name: 'description', content: translated[parts[1]] });
        });
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.instana.reportPage(val.urlAfterRedirects);
            }
        });
    }

    showBannerOnDate() {
        if (this.currentDate >= this.startDate) {
            return true;
        }
    }

    getColorScheme() {
        if (this.campaign) {
            return 'color-scheme-' + this.campaign;
        }
    }

    private registerIcons(set: { [key: string]: string }): void {
        if (set) {
            Object.keys(set).forEach((icon) => {
                const [namespace, name] = icon.split(':');
                const url = this.sanitizer.bypassSecurityTrustResourceUrl(set[icon]);
                this.iconRegistry.addSvgIconInNamespace(namespace, name, url);
            });
        }
    }
}
