import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationFooter } from '../navigation/navigation-footer.interface';

/**
 * renders footer container including recommended section, company section, base section with social media and legal links
 *
 * @param {NavigationFooter} menu menu configuration
 */
@Component({
    selector: 'vi-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
    @Input() menu?: NavigationFooter;

    menuCampaign = 'footermenu';

    constructor() {}

    ngOnInit() {}
}
