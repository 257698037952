import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'vi-card-button',
    templateUrl: './card-button.component.html',
    styleUrls: ['./card-button.component.scss'],
})
export class CardButtonComponent implements OnInit {
    @Input() text: string;
    @Input() description: string;
    @Input() link: string;

    @Output() click = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    gotoLink() {
        this.click.emit();
        window.location.href = this.link;
    }
}
