import { MatDialogRef } from '@angular/material/dialog';
import { Observable, throwError, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/**
 * Retry-when operator function - to retry request if defined response codes detected (0 - no connectivity by default)
 *
 * @param codes
 * @param dialog Function returning MatDialogRef - will use returned value to retry or cancel; optional
 *                  - uses auto-retry if no dialog provided (3 times within 1, 2, 3 sec waiting time)
 */
export const ApiErrorOccurs = (codes: number[] = [0], dialog?: () => MatDialogRef<any>) => (
    attempts: Observable<any>
) => {
    const duration = 1000;
    const max = 3;

    const check = (error: { status: number }, attempt: number) => {
        const retry = attempt + 1;
        // if code different than defined to handle - re-throw error
        // also if no dialog defined and number of retries exceeds max
        if (!codes.some((e) => e === error.status) || (!dialog && retry > max)) {
            return throwError(error);
        }

        if (dialog) {
            return dialog()
                .afterClosed()
                .pipe(mergeMap((agree) => (agree && of({})) || throwError(error)));
        } else {
            return timer(duration * retry);
        }
    };

    return attempts.pipe(mergeMap((error: { status: number }, i) => check(error, i)));
};
