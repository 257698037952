import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadlineComponent } from './headline/headline.component';
import { TranslateModule } from '@ngx-translate/core';
import { TrustHtmlPipe } from './trust-html.pipe';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { NgxUploaderModule } from 'ngx-uploader';
import { MatCardModule } from '@angular/material';
import { ProgressHeadComponent } from './progress-head/progress-head.component';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CardButtonComponent } from './card-button/card-button.component';

const declarations = [
    HeadlineComponent,
    ProgressHeadComponent,
    TrustHtmlPipe,
    CardButtonComponent,
    ConfirmationDialogComponent,
];

const angularModules = [
    FlexModule,
    FlexLayoutModule,
    MatCardModule,
    NgxUploaderModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
];

@NgModule({
    declarations,
    imports: [CommonModule, TranslateModule.forChild(), ...angularModules],
    exports: [TranslateModule, ...declarations, ...angularModules],
    entryComponents: [ConfirmationDialogComponent],
})
export class SharedModule {}
