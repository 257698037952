import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Navigation } from '../navigation/navigation.interface';
import { Toggleable } from '../navigation/toggleable.interface';
import { User } from '../../user/user.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../user/auth.service';
import { delay, map } from 'rxjs/operators';

/**
 * Renders top bar as header, including top/right navigation and side menu toggle buttons
 *
 * @param {Toggleable} toggleable  Toggleable (f.e. side navigation) components
 * @param {Navigation} menu Menu definition
 * @param {string} logo Logo file url
 */
@Component({
    selector: 'vi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    authenticated$: Observable<boolean>;
    user: User;

    constructor(public auth: AuthService) {
        this.authenticated$ = this.auth.csrfToken$.pipe(
            delay(10),
            map((token) => {
                return !!token;
            })
        );
    }

    @Input() toggleable?: Toggleable;
    @Input() menu?: Navigation;
    @Input() logo?: string;

    get isLoggedIn(): boolean {
        return this.auth.hasValidCsrfToken();
    }
    ngOnInit(): void {}
}
