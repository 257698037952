import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StatusPageService, StatusSummary } from './status-page.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
    constructor(private statusPage: StatusPageService, private router: Router) {}

    public canActivate(): Observable<boolean> {
        const ignoreFlag = localStorage.getItem('ignore_statuspage');
        if (ignoreFlag === 'true') {
            return of(true);
        }
        return this.statusPage.fetchStatus().pipe(
            map(
                (status) => this.checkStatus(status),
                catchError(() => of(true))
            )
        );
    }

    private checkStatus(status: StatusSummary): boolean {
        switch (status.status) {
            case 'major_outage':
            case 'under_maintenance': {
                this.router.navigate(['maintenance'], {
                    state: status,
                });
                return false;
            }

            default:
                return true;
        }
    }
}
