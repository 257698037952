import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment, parseZone as moment } from 'moment';

@Injectable()
export class AppDateAdapter extends MomentDateAdapter {
    /**
     * Overrides default parse method with strict mode
     */
    parse(value: any, parseFormat: string | string[]): Moment | null {
        // return parsed always
        return moment(value, parseFormat, this.locale);
    }

    /**
     * Formats within format defined only if complete
     */
    format(date: Moment, displayFormat: string): string {
        const complete = date.parsingFlags().unusedTokens.length === 0;

        return complete ? super.format(date, displayFormat) : (date.creationData().input as string);
    }
}
