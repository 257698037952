import { Component, OnInit } from '@angular/core';
import { PlannedMaintenance, StatusPageService, StatusSummary } from '../../services/status-page.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'vi-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
    public type: string;
    public maintenance: PlannedMaintenance;

    constructor(private statusPage: StatusPageService) {
        const state: StatusSummary = history.state;
        this.type = state.status;
    }

    ngOnInit() {
        this.statusPage
            .getStatus()
            .pipe(filter((s) => !!s))
            .subscribe((status) => {
                this.maintenance = status.next_maintenance;
            });
    }
}
