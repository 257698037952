/*
 * Common config for link groups - to be used in environments
 */

const campaignParams = '?utm_source=app.foerder-profi.de&utm_medium=referral&utm_campaign=';
const hydrobalanceParams = '?hydrobalance=true&started=true';

export const links = {
    types: {
        // @translate subc.navigation.menu.types
        identifier: 'types',
        items: [
            {
                // @translate subc.navigation.menu.oil-heating
                label: 'oil-heating',
                url: `https://heizung.de/oelheizung/${campaignParams}`,
            },
            {
                // @translate subc.navigation.menu.gas-heating
                label: 'gas-heating',
                url: `https://heizung.de/gasheizung/${campaignParams}`,
            },
            {
                // @translate subc.navigation.menu.heat-pump
                label: 'heat-pump',
                url: `https://heizung.de/waermepumpe/${campaignParams}`,
            },
            {
                // @translate subc.navigation.menu.fuel-cell
                label: 'fuel-cell',
                url: `https://heizung.de/brennstoffzellenheizung/${campaignParams}`,
            },
        ],
    },
    subjects: {
        // @translate subc.navigation.menu.subjects
        identifier: 'subjects',
        items: [
            {
                // @translate subc.navigation.menu.heating-overview
                label: 'heating-overview',
                url: `https://heizung.de/heizung/heizungsueberblick/${campaignParams}`,
            },
            {
                // @translate subc.navigation.menu.heating-guides
                label: 'heating-guides',
                url: `https://heizung.de/heizung/ratgeber/${campaignParams}`,
            },
            {
                // @translate subc.navigation.menu.heating-modernization
                label: 'heating-modernization',
                url: `https://heizung.de/heizung/modernisierung/${campaignParams}`,
            },
            {
                // @translate subc.navigation.menu.heating-support
                label: 'heating-support',
                url: `https://heizung.de/heizung/foerderung/${campaignParams}`,
            },
        ],
    },
    services: {
        // @translate subc.navigation.menu.services
        identifier: 'services',
        items: [
            {
                // @translate subc.navigation.menu.downloads
                label: 'downloads',
                routerLink: 'downloads',
            },
            {
                // @translate subc.navigation.menu.find-specialist
                label: 'find-specialist',
                url: `https://heizung.de/installateur-finden/${campaignParams}`,
            },
            {
                // @translate subc.navigation.menu.request-expert-advice
                label: 'request-expert-advice',
                url: `https://heizung.de/angebot-anfordern-foerderprofi/${campaignParams}`,
            },
            {
                label: 'information-for-architects',
                // @translate subc.navigation.menu.information-for-architects
                url: `https://www.viessmann.de/de/architekten.html${campaignParams}`,
            },
        ],
    },
    legal: {
        items: [
            {
                label: 'imprint',
                // @translate subc.navigation.menu.imprint
                url: 'https://www.foerder-profi.de/impressum',
            },
            {
                label: 'dataprivacy',
                // @translate subc.navigation.menu.dataprivacy
                url: 'https://www.foerder-profi.de/datenschutz',
            },
            {
                label: 'agb',
                // @translate subc.navigation.menu.agb
                url: 'https://www.foerder-profi.de/agb',
                target: '_blank',
            },
        ],
    },
    application: {
        // @translate subc.navigation.menu.application
        identifier: 'application',
        items: [
            {
                // @translate subc.navigation.menu.new-application
                label: 'new-application',
                url: '/',
                icon: 'library_add',
            },
            {
                // @translate subc.navigation.menu.my-applications
                label: 'my-applications',
                routerLink: 'dashboard',
                icon: 'content_copy',
            },
        ],
    },
    hydrobalance: {
        // @translate subc.navigation.menu.hydrobalance
        identifier: 'hydrobalance',
        items: [
            {
                // @translate subc.navigation.menu.new-hydrobalance
                label: 'new-hydrobalance',
                url: `/${hydrobalanceParams}`,
                icon: 'library_add',
            },
            {
                // @translate subc.navigation.menu.my-hydrobalances
                label: 'my-hydrobalances',
                url: 'https://hydraulischer-abgleich.foerder-profi.de/project',
                target: '_blank',
                icon: 'content_copy',
            },
        ],
    },
    downloads: {
        // @translate subc.navigation.menu.services
        identifier: 'downloads',
        items: [
            {
                // @translate subc.navigation.menu.downloads
                label: 'downloads',
                routerLink: 'downloads',
            },
        ],
    },
    social: {
        identifier: 'social',
        items: [
            {
                url: 'https://www.linkedin.com/company/f%C3%B6rderprofi/about/',
                icon: 'subc:linkedIn',
                target: '_blank',
            },
            {
                url: 'https://www.youtube.com/@forderprofi1036',
                icon: 'subc:youtube',
                target: '_blank',
            },
        ],
    },
};
