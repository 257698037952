import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export type Status = 'major_outage' | 'under_maintenance' | 'partial_outage' | 'degraded_performance' | 'operational';

export interface PlannedMaintenance {
    start_date: string;
    end_date: string;
    remind_from: string;
    status: string;
}

export interface StatusSummary {
    id: string;
    name: string;
    status: Status;
    next_maintenance?: PlannedMaintenance;
}

@Injectable({
    providedIn: 'root',
})
export class StatusPageService {
    private currentStatus$: BehaviorSubject<StatusSummary> = new BehaviorSubject<StatusSummary>(undefined);
    constructor(private http: HttpClient) {}

    public fetchStatus(): Observable<StatusSummary> {
        return this.http
            .get<StatusSummary>(environment.http.statusPage)
            .pipe(tap((status) => this.currentStatus$.next(status)));
    }

    public getStatus(): Observable<StatusSummary> {
        return this.currentStatus$;
    }
}
