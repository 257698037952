import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { MandateService } from '../../mandate/mandate.service';
import { HttpParams } from '@angular/common/http';
import { MandateState } from '../../mandate/mandate.model';
import { environment } from '../../../environments/environment';
import { AppConfig } from '../../app.config';
import { ConfigitApiService } from '../../services/configit-api.service';
import { ConfigitAssignment } from '../../modules/configit-quest-adapter/configit-types';
import { filter, map } from 'rxjs/operators';
import { User } from '../../user/user.model';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'vi-assign-order',
    templateUrl: './assign-order.component.html',
    styleUrls: ['./assign-order.component.scss'],
})
export class AssignOrderComponent implements OnInit {
    leadId: string;
    confirmationKey: string;
    isEndCustomer: boolean;
    multiFlow: boolean;
    loading = true;

    embedded = false;

    state: MandateState = MandateState.eligible;

    errorOccurred = false;

    registerUrl: string;
    loginUrl: string;

    showHintForEndCustomer: boolean;
    showHintForInstaller: boolean;
    showHintForLiteAccount: boolean;

    constructor(
        private route: ActivatedRoute,
        private meta: Meta,
        private router: Router,
        private userService: UserService,
        private mandateService: MandateService,
        private config: AppConfig,
        private configitApi: ConfigitApiService
    ) {}

    public ngOnInit() {
        const query = this.route.snapshot.queryParams;
        if (query.embedded) {
            this.embedded = true;
        }
        this.meta.updateTag({ name: 'referrer', content: 'unsafe-url' });
        this.leadId = this.route.snapshot.params.leadId;
        this.multiFlow = this.route.snapshot.queryParams.multiflow === 'true';
        this.confirmationKey = this.route.snapshot.queryParams.confirmationKey;
        const requestor = this.route.snapshot.queryParams.requestor;
        const isEmployee = requestor === 'Employee';
        this.isEndCustomer = requestor === 'Customer';
        this.userService.getLoggedInUser('identity').subscribe(
            (user) => {
                if (isEmployee || this.validateRequestorMatchesAccount(user)) {
                    // user is logged in, validate and assign mandate
                    this.assignOrder();
                }
            },
            () => {
                if (isEmployee) {
                    // employee is directly sent to login screen
                    window.location.href = this.getLoginUrl();
                }
                // user is not logged in, populate login / register controls
                this.populateControls();
            }
        );
    }

    private validateRequestorMatchesAccount(user: User) {
        this.loading = false;
        if (user.isLite) {
            this.showHintForLiteAccount = true;
            return false;
        }
        if (user.properties) {
            const identityProp = user.properties.find((p) => p.name === 'IdentityType');
            if (identityProp) {
                const type = identityProp.value;
                // we can have two types of mismatch
                if (type === 'KuMA' && this.isEndCustomer) {
                    // user is a ZH but filled out the subsidy request for an end customer
                    this.showHintForInstaller = true;
                    return false;
                } else if (!this.isEndCustomer && ['ViInt', 'EK'].includes(type)) {
                    // user is end customer but filled out the request for installer
                    this.showHintForEndCustomer = true;
                    return false;
                }
            }
        }
        return true;
    }

    private populateControls() {
        this.fetchPersonDataFromConfigit()
            .pipe(
                map(({ newAssignments }) => {
                    const leadSource = newAssignments.find(
                        (a) => a.variableName === 'LandingPage.BasicData.LeadSource'
                    );
                    const isViessmannPartner = newAssignments.find(
                        (a) => a.variableName === 'Installer.ViessmannPartner'
                    );
                    if (
                        leadSource &&
                        leadSource.valueName.toLowerCase() === 'zvshk' &&
                        isViessmannPartner &&
                        isViessmannPartner.valueName === 'no'
                    ) {
                        this.redirectToMandateConfirmation(this.route.snapshot.queryParams.order);
                        return undefined;
                    }
                    return newAssignments;
                }),
                filter(Boolean),
                map((assignments: ConfigitAssignment[]) => this.createRegisterPrefillPayload(assignments))
            )
            .subscribe(
                (prefill) => {
                    const registerPath = this.isEndCustomer ? 'register-end-customer' : 'register';
                    const currentUrl = encodeURIComponent(window.location.href);
                    Object.keys(prefill).forEach((key) => prefill[key] === undefined && delete prefill[key]); // remove undefined values
                    let registerParams = new HttpParams({ fromObject: prefill });
                    if (!this.isEndCustomer) {
                        // prevent creation of lite account
                        registerParams = registerParams.append('hideNoCompanyNumberOption', 'true');
                    }
                    this.registerUrl = `${
                        environment.auth.registerBaseUrl
                    }/${registerPath}?${registerParams.toString()}&redirect=${currentUrl}`;

                    this.loginUrl = this.getLoginUrl(this.registerUrl);
                    // after login, IAM should redirect user back here with a login

                    this.loading = false;
                },
                (error) => {
                    console.log('failed to get person data from configit');
                    console.log(error);
                    this.errorOccurred = true;
                }
            );
    }

    private getLoginUrl(registerUrl?: string) {
        let params = new HttpParams();
        params = params.append('appId', environment.auth.appId);
        if (registerUrl) {
            params = params.append('ForgotUsername', `${environment.auth.registerBaseUrl}/forgot-username`);
            params = params.append('RegistrationLink', registerUrl);
        }
        return `${environment.auth.baseUrl}/saml/sso/request?${params.toString()}`;
    }

    private fetchPersonDataFromConfigit() {
        // call getFromExistingConfiguration to get RawData of submitted questionnaire
        // and extract the user information for prefilling the registration form
        return this.configitApi.getConfiguration({
            material: this.config.quest,
            order: {
                ...(!this.multiFlow && { leadId: this.leadId }),
                ...(this.multiFlow && { opportunityId: this.leadId }),
                confirmationKey: this.confirmationKey,
            },
        });
    }

    private createRegisterPrefillPayload(assignments: ConfigitAssignment[]) {
        if (this.isEndCustomer) {
            return {
                salutation: this.safeFind(assignments, 'Beneficiary.PersonalData.Title'),
                email: this.safeFind(assignments, 'Beneficiary.PersonalData.Mail'),
                firstName: this.safeFind(assignments, 'Beneficiary.PersonalData.FirstName'),
                lastName: this.safeFind(assignments, 'Beneficiary.PersonalData.LastName'),
                street: this.safeFind(assignments, 'Beneficiary.AdressData.Street'),
                streetNo: this.safeFind(assignments, 'Beneficiary.AdressData.HouseNo'),
                postalCode: this.safeFind(assignments, 'Beneficiary.AdressData.Postcode'),
                city: this.safeFind(assignments, 'Beneficiary.AdressData.Location'),
            };
        } else {
            return {
                companyNumber: this.safeFind(assignments, 'BasicData.Installer.CustomerID'),
                email: this.safeFind(assignments, 'BasicData.Installer.Mail'),
                firstName: this.safeFind(assignments, 'BasicData.Installer.FirstName'),
                lastName: this.safeFind(assignments, 'BasicData.Installer.LastName'),
            };
        }
    }

    private safeFind(assignments: ConfigitAssignment[], variableName: string) {
        const value = assignments.find((a) => a.variableName === variableName);
        if (value) {
            return encodeURIComponent(value.valueName);
        }
    }

    private redirectToMandateConfirmation(order: string) {
        return this.router
            .navigate(['/'], {
                queryParams: {
                    order: order,
                    ck: this.confirmationKey,
                    ...(!this.multiFlow && { ld: this.leadId }),
                    ...(this.multiFlow && { oid: this.leadId }),
                    ...(this.embedded && {
                        embedded: 'true',
                        campaign: this.route.snapshot.queryParams.campaign,
                    }),
                },
            })
            .then(() => {
                window.location.reload();
            });
    }

    private assignOrder() {
        this.loading = true;
        this.mandateService.assignMandate(this.leadId, this.confirmationKey).subscribe(
            ({ order }) => {
                console.log('order was assigned, redirecting...');
                if (order) {
                    this.redirectToMandateConfirmation(order);
                } else {
                    this.router.navigate(['dashboard']);
                }
            },
            (error) => {
                console.log('failed to assign order');
                console.log(error);
                this.errorOccurred = true;
            }
        );
    }
}
