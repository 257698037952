import { Component, Input, OnInit } from '@angular/core';
import { Mandate, MandateState } from '../../../mandate/mandate.model';

@Component({
    selector: 'vi-progress-head',
    templateUrl: './progress-head.component.html',
    styleUrls: ['./progress-head.component.scss'],
})
export class ProgressHeadComponent implements OnInit {
    @Input() state: MandateState;
    @Input() mandate: Mandate;

    STATE = MandateState;
    currentState: string;
    currentSubState: string;

    statesMap: { [key: string]: number };
    allStates: string[];

    eligibilityTypeKfw: boolean;

    constructor() {}

    ngOnInit() {
        if (this.state === MandateState.closedAndWon) {
            // treat closed and won as closed
            this.state = MandateState.closed;
        }
        this.populateStatesMap();
        this.allStates = Object.keys(this.statesMap);

        if (this.mandate) {
            this.currentState = this.mandate.state.toString();
            this.currentSubState = this.mandate.subState && this.mandate.subState.toString();
        } else {
            this.currentState = this.state;
            this.currentSubState = 'pending';
        }

        if (this.mandate.typeOfSubsidy.includes('KfW')) {
            this.eligibilityTypeKfw = true;
        }
    }

    populateStatesMap() {
        // MandateState.converting is a special case that replaces MandateState.assigned.
        // this is only relevant if the mandate is currently in that state
        const assignedStep = this.state === MandateState.converting ? MandateState.converting : MandateState.assigned;
        this.statesMap = {
            [MandateState.eligible]: 0,
            [assignedStep]: 1,
            [MandateState.approved]: 2,
            [MandateState.confirmed]: 3,
            [MandateState.complete]: 4,
            [MandateState.closed]: 5,
        };
    }

    isCurrent(state: MandateState) {
        return this.statesMap[state] === this.statesMap[this.state] + 1;
    }

    isReview() {
        if (this.currentState === 'confirmed' && this.currentSubState === 'documentsInReview') {
            return true;
        }
    }

    isComplete(state: MandateState) {
        return this.statesMap[state] <= this.statesMap[this.state];
    }
}
