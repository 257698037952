import { Component, OnInit } from '@angular/core';
import { MandateState } from '../../mandate/mandate.model';
import { MandateService } from '../../mandate/mandate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { subCategories } from './contact';

@Component({
    selector: 'vi-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    mandates = [];
    hasMandates: boolean;
    error = false;
    mandateNo: string;
    disableOnRequest;
    loading = true;
    subCategories = subCategories;
    category = 'Change request';
    subCategory: string;
    subject: string;
    description: string;
    showError: boolean;
    subCats = [];
    pending = false;
    success = false;

    constructor(
        private mandateService: MandateService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.loading = true;
        this.mandateService.getMandates().subscribe(
            (mandates) => {
                this.hasMandates = mandates.length > 0;
                this.populateMandates(mandates);
                this.loading = false;
            },
            (error) => {
                this.error = true;
                console.log(error);
            }
        );
        this.activatedRoute.queryParams.subscribe((params) => {
            const { id } = params;
            if (id) {
                this.mandateNo = id;
                this.disableOnRequest = true;
            }
        });

        this.transformCategories();
    }

    refreshForm() {
        this.router.navigate(['/contact']).then(() => {
            window.location.reload();
        });
    }

    transformCategories() {
        this.subCategories.forEach((subCategory) => {
            const object = { value: subCategory, description: subCategory.toLowerCase().replace(/\s/g, '') };
            this.subCats.push(object);
        });
    }

    populateMandates(mandates) {
        const closed = mandates.filter((m) => m.state === MandateState.closedAndWon);
        const awaiting = mandates.filter(
            (m) => ![MandateState.eligible, MandateState.closedAndWon, MandateState.converting].includes(m.state)
        );
        this.mandates = awaiting.concat(closed);
        this.hasMandates = this.mandates.length > 0;
    }

    submitRequest() {
        this.pending = true;
        this.mandateService
            .createCase(this.mandateNo, this.category, this.subCategory, this.subject, this.description)
            .subscribe(
                (res) => {
                    if (res.status === 200) {
                        this.pending = false;
                        this.success = true;
                        this.error = false;
                    }
                },
                () => {
                    this.pending = false;
                    this.error = true;
                    this.success = false;
                }
            );
    }
}
