import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { Directive, OnDestroy, ElementRef, Renderer2, Input, AfterViewInit } from '@angular/core';

/**
 * Directive injects empty fixed container and class attributes into base container,
 * it toggles classes based on visibility.
 * Used classes are:
 * .iv-in: base container or first child container for given selector is visible in viewport
 * .iv-out: base container or first child container for given selector is not visible in viewport
 * .iv-fixed: class for fixed empty container
 *
 * @param viInView optional child element selector
 *
 * @example
 * <div viInView=".some-child">
 *   <div class="some-child">
 *   </div>
 * </div>
 */

@Directive({
    selector: '[viInView]',
})
export class InViewDirective implements OnDestroy, AfterViewInit {
    private host: Element;
    private scroll: Subscription;
    private resize: Subscription;
    private mutation: MutationObserver;

    @Input() viInView: string;

    constructor(private element: ElementRef, private renderer: Renderer2) {
        this.host = this.element.nativeElement;
    }

    ngAfterViewInit() {
        this.scroll = this.observe('scroll', 100).subscribe(() => this.check('scroll'));
        this.resize = this.observe('resize', 25).subscribe(() => this.check('resize'));

        this.mutation = new MutationObserver(() => this.initialize());
        this.mutation.observe(this.host, { childList: true });

        this.initialize();
    }

    ngOnDestroy() {
        if (this.scroll) {
            this.scroll.unsubscribe();
        }
        if (this.resize) {
            this.resize.unsubscribe();
        }
        if (this.mutation) {
            this.mutation.disconnect();
        }
    }

    /**
     * Initializes elements and first check
     */
    private initialize(): void {
        this.targets().forEach((target: Element) => this.appendFixed(target));
        this.check('resize');
    }

    /**
     * Gets observed targets
     */
    private targets(): Element[] {
        // pointed by selector or host itself
        return this.viInView ? Array.from(this.host.querySelectorAll(this.viInView)) : [this.host];
    }

    /**
     * Creates empty element and append it this to given element
     *
     * @param target
     */
    private appendFixed(target: Element): void {
        // check if already appended
        if (!target.querySelector('.iv-fixed')) {
            // then create
            const child = this.renderer.createElement('div');
            this.renderer.addClass(child, 'iv-fixed');
            this.renderer.appendChild(target, child);
        }
    }

    /**
     * Checks visibility of target
     *
     * @param event
     * @param partial
     */
    private check(event: string, partial: boolean = true): void {
        const first = this.targets()[0];

        if (!first) {
            // nothing to check
            return;
        }

        const checking = first.getBoundingClientRect();
        // visible bonduaries
        const visible = {
            top: checking.top >= 0 && checking.top < window.innerWidth,
            bottom: checking.bottom > 0 && checking.bottom <= window.innerHeight,
        };
        // then if in view (partial/whole)
        const inview = partial ? visible.top || visible.bottom : visible.top && visible.bottom;

        this.set(inview ? 'in' : 'out');

        if (event === 'resize') {
            this.alignPositions();
        }
    }

    /**
     * Sets class according to being in/out view
     *
     * @param kind
     */
    private set(kind: 'in' | 'out'): void {
        const opposite = kind === 'in' ? 'iv-out' : 'iv-in';

        this.renderer.removeClass(this.host, opposite);
        this.renderer.addClass(this.host, 'iv-' + kind);
    }

    /**
     * Aligns position of injected fixed elements
     */
    private alignPositions() {
        // update position for fixed container
        Array.from(this.host.querySelectorAll('.iv-fixed')).forEach((fixed: HTMLElement) => {
            const parent = fixed.parentElement.getBoundingClientRect();
            fixed.style.left = parent.left + 'px';
            fixed.style.width = parent.width + 'px';
        });
    }

    /**
     * Creates debounced observer on window event
     */
    private observe(event: string, debounce: number): Observable<Event> {
        return observableFromEvent(window, event).pipe(debounceTime(debounce));
    }
}
