import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { NavigationComponent } from './navigation.component';
import { Toggleable } from './toggleable.interface';
import { NavigationGroup } from './navigation-group.interface';

/**
 * Renders side navigation and close toggle button
 *
 * @param {Toggleable} toggleable  Toggleable (f.e. side navigation) components
 * @param {NavigationGroup[]} menu array of navigation groups
 * @param {NavigationGroup[]} optional array of navigation groups of to be shown on small devices
 * @param {string} context string identifier for headline used as translation key context ('subc.navigation.menu.' + context)
 */
@Component({
    selector: 'vi-navigation-side',
    templateUrl: './navigation-side.component.html',
    styleUrls: ['./navigation-side.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavigationSideComponent extends NavigationComponent implements OnInit, OnChanges {
    @Input() context: string;
    @Input() toggleable?: Toggleable;
    @Input() optional?: NavigationGroup[];

    optionalMenu: NavigationGroup[];

    menuCampaign = 'burgermenu';

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.optional) {
            // within optional menu combine items of anonymous groups to one first group
            const anonymous = [];
            this.optionalMenu = [];
            (changes.optional.currentValue || []).forEach((group) => {
                if (!group.identifier) {
                    anonymous.push(...group.items);
                } else {
                    this.optionalMenu.push(group);
                }
            });
            if (anonymous.length) {
                this.optionalMenu.unshift({ items: anonymous });
            }
        }
    }
}
