import { Component, Input, OnInit } from '@angular/core';

import { NavigationGroup } from './navigation-group.interface';

/**
 * Renders menu navigation as simple link, if only one item is included one navigation group,
 * or as groupname as menu toggle opening submenu if more than one item are included in navigation group
 *
 * @param {NavigationGroup[]} menu array of navigation groups
 */
@Component({
    selector: 'vi-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
    @Input() menu: NavigationGroup[];

    constructor() {}

    ngOnInit() {}
}
