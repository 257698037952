import { SalesforceTask } from '../salesforce/salesforce.model';

export enum MandateState {
    eligible = 'eligible',
    assigned = 'assigned',
    approved = 'approved',
    confirmed = 'confirmed',
    complete = 'complete',
    closed = 'closed',
    converting = 'converting', // artificial state in case the lead is converted to an opportunity
    closedAndWon = 'closedAndWon',
}

export enum MandateSubState {
    pending = 'pending',
    handingInApplication = 'handingInApplication',
    awaitingUpload = 'awaitingUpload',
    processingApplication = 'processingApplication',
    documentsInReview = 'documentsInReview',
    documentsInExternalReview = 'documentsInExternalReview',
    requestingPayout = 'requestingPayout',
    paymentRequested = 'paymentRequested',
}

export interface PersonalData {
    firstName: string;
    lastName: string;
    name: string;
    postalCode: string;
    city: string;
    street: string;
    houseNumber?: string;
}

export interface BuildingObject {
    postalCode?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
}

export enum MandateType {
    Lead,
    Opportunity,
}

export interface Mandate {
    id: string;
    confirmationKey?: string;
    type?: MandateType;
    personalData: PersonalData;
    mandateNo: string;
    rawData?: any;
    state?: MandateState;
    subState?: MandateSubState;
    projectClosure?: string;
    tasks?: SalesforceTask[];
    handledInAsana?: boolean;
    openTasks?: number;
    typeOfSubsidy: string;
    closeDate: string;
    buildingObject?: BuildingObject;
    tpbNumber?: string;
    tpnNumber?: string;
    contractor?: string;
    vitoflowId?: string;
    endcustomerIban?: string;
    bzaId?: string;
    bndId?: string;
}

export interface Attachment {
    id: string;
    type: string;
    fileName: string;
    size: number;
    uploadedAt: string;
    visibility: string;
    owner: AttachmentOwner;
}

export interface AttachmentOwner {
    id: string;
    name: string;
    username: string;
}
