import { Directive, HostListener, HostBinding } from '@angular/core';

/**
 * Directive binds click and redirects to home url (/)
 *
 * @example
 * <img redirectHome ...>
 */

@Directive({
    selector: '[viRedirectHome]',
})
export class RedirectHomeDirective {
    @HostBinding('style.cursor') cursor = 'pointer';

    @HostListener('click') onClick() {
        window.location.href = '/';
    }
}
