import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
@Component({
    selector: 'vi-info-banner',
    templateUrl: './info-banner.component.html',
    styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent {
    constructor(private translationService: TranslateService) {}
    featureMessage$ = combineLatest([
        this.translationService.onLangChange.pipe(startWith({})),
        this.translationService.getTranslation(this.translationService.currentLang).pipe(startWith({})),
    ]).pipe(
        switchMap(() => this.translationService.get('subc')),
        map((translation) => (translation['info_banner'] ? translation['info_banner'].message : ''))
    );
}
