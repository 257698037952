import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiBase } from '../api';
import { environment } from '../../environments/environment';

import {
    ConfigitAssignment,
    ConfigitEnvSettings,
    ConfigitMandate,
    ConfigitMandates,
} from '../modules/configit-quest-adapter/configit-types';
import { ConfigitTemplateResp, ConfigitTemplate } from '../modules/configit-quest-adapter/configit-types';
import { ConfigitConfigurationResp, ConfigitConfiguration } from '../modules/configit-quest-adapter/configit-types';
import { ConfigitAssignmentResp } from '../modules/configit-quest-adapter/configit-types';
import { HttpParams } from '@angular/common/http';
import { SalesforceOrder } from '../salesforce/salesforce.model';
/**
 * Configit specialized service
 */

@Injectable({
    providedIn: 'root',
})
export class ConfigitApiService extends ApiBase {
    protected baseUrl = environment.configit.url;

    config(): ConfigitEnvSettings {
        return environment.configit;
    }

    /**
     * Gets template
     *
     * @param data Additional params replacing defaults form environment.configit
     * @returns Template structure (first)
     */
    getTemplate(data: any = {}): Observable<ConfigitTemplate> {
        const params = this.templateParams(data);

        return this.request('post', '/getMaterialTemplateData', params).pipe(
            map((resp: ConfigitTemplateResp) => resp.templates[0])
        );
    }

    /**
     * Gets configuration
     *
     * @param data Additional params replacing defaults from environment.configit
     * @param assignments Initial assignments
     * @returns Configuration structure (extracted from root)
     */
    getConfiguration(data: any = {}, assignments?: any, authenticated = true): Observable<ConfigitConfiguration> {
        const params = this.configurationParams(data, assignments);

        return this.request('post', '/getFromExistingConfiguration', params, {}, authenticated).pipe(
            map((resp: ConfigitConfigurationResp) => resp.root.configuration)
        );
    }

    /**
     * Get mandates for given uuid
     * @param uuid uuid of mandate
     */
    getMandate(uuid): Observable<ConfigitMandate> {
        return this.request('get', `/users/${uuid}`, undefined, undefined, true);
    }

    /**
     * Get all mandates
     * @param opts filter options to pass to endpoint
     */
    getMandates(opts?: any): Observable<ConfigitMandates> {
        let url = '/users';
        if (opts) {
            // convert to http params
            let params = new HttpParams();
            Object.keys(opts).forEach((key) => {
                params = params.append(key, opts[key]);
            });
            url = `${url}?${params.toString()}`;
        }
        return this.request('get', url, undefined, undefined, true);
    }

    /**
     * Update mandate data
     * @param mandate mandate to update
     */
    updateMandate(mandate: ConfigitMandate) {
        return this.request('put', `/users/${mandate.uuid}`, mandate.data, undefined, true);
    }

    /**
     * Submits update value
     *
     * @param name Material name
     * @param variable Variable name
     * @param value Variable value
     * @param existing Existing assignments
     * @param data Additional params replacing defaults form environment.configit
     * @returns Configuration data
     */
    setAssignment(
        name: string,
        variable: string,
        value: any,
        existing?: any,
        data?: any
    ): Observable<ConfigitConfiguration> {
        const params = this.updateParams(name, variable, value, existing, data);

        return this.request('post', '/updateValues', params).pipe(
            map((resp: ConfigitAssignmentResp) => this.checkErrors(variable, value, resp)),
            map((resp: ConfigitAssignmentResp) => resp.bomDeltaConfigurationData.configurationData)
        );
    }

    /**
     * Submits configuration
     *
     * @param material Material name
     * @param assignments Existing assignments
     * @param order Order identifier
     * @param opportunityId opportunity id
     * @param captcha Captcha response
     * @param recommend Recommend flag
     * @param discount discount to apply
     * @returns Configuration structure (extracted from root)
     */
    submitConfiguration(
        material: string,
        assignments?: any,
        order?: SalesforceOrder,
        opportunityId?: string,
        captcha?: string,
        recommend?: boolean,
        discount?: number
    ): Observable<ConfigitConfigurationResp> {
        const data: ConfigitEnvSettings = {
            material,
            captcha,
            order,
            opportunityId,
            recommend,
            submit: 'true',
            discount,
        };
        const params = this.configurationParams(data, assignments);

        return this.request('post', '/configurations', params, undefined, true);
    }

    public subsidy(assignments: ConfigitAssignment[]) {
        const transformed = assignments.reduce((prev, curr) => ({ ...prev, [curr.variableName]: curr.valueName }), {});
        return this.request('post', '/subsidy', transformed);
    }

    protected checkErrors(variable: string, value: any, resp: ConfigitAssignmentResp): ConfigitAssignmentResp {
        // as error detected, move generic/unknown error to configuration structure
        if (resp.assignmentError) {
            resp.bomDeltaConfigurationData = {
                configurationData: {
                    uiGroupStates: [],
                    // put invalid value to the state as can be consumed by transformer to keep in UI
                    variableStates: [
                        {
                            fullyQualifiedName: variable,
                            invalidMessage: 'error.invalid',
                            invalidValue: value,
                        },
                    ],
                    // but remove from assignments if exists (f.e. previous valid)
                    assignmentsToRemove: [
                        {
                            variableName: variable,
                        },
                    ],
                },
            };
        }

        return resp;
    }

    /**
     * Constructs template get params - combining given params and defaults from config
     *
     * @param params
     */
    protected templateParams(params: ConfigitEnvSettings = {}): any {
        const config = this.config();

        return {
            name: params.material || config.material,
            languages: params.languages || config.languages,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            salesAreaName: params.salesAreaName || config.salesAreaName,
        };
    }

    /**
     * Constructs configuration get params - combining given params and defaults from config
     *
     * @param params
     * @param assignments
     */
    protected configurationParams(params: ConfigitEnvSettings = {}, assignments: any[] = []): any {
        const config = this.config();

        return {
            name: params.material || config.material,
            languages: params.languages || config.languages,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            salesAreaName: params.salesAreaName || config.salesAreaName,
            rootConfiguration: {
                existingAssignments: assignments,
                materialName: params.material || config.material,
            },
            environment: {
                rootEnvironment: {
                    preselect: params.preselect,
                    submit: params.submit,
                    opportunityId: params.opportunityId,
                    ...params.order,
                    captcha: params.captcha,
                    recommend: params.recommend && 'true',
                    discount: params.discount,
                    hydrobalance: params.hydrobalance,
                },
            },
        };
    }

    protected updateParams(
        name: string,
        variable: string,
        value: string,
        assignments: any[] = [],
        params: ConfigitEnvSettings = {}
    ) {
        const config = this.config();

        return {
            name,
            languages: params.languages || config.languages,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            salesAreaName: params.salesAreaName || config.salesAreaName,

            assignment: {
                existingAssignments: assignments,
                itemId: '',
                newAssignment: {
                    action: 'updateValues',
                    assignment: {
                        variableName: variable,
                        updatedValues: [{ value: value, updateType: 1 }],
                        isDefault: false,
                    },
                },
            },
            refreshBom: true,
            environment: {
                rootEnvironment: {
                    ...params.order,
                    discount: params.discount,
                },
            },
        };
    }

    protected extract(resp: Observable<any>, part: string = 'data'): Observable<any> {
        return resp.pipe(map((r: any) => r[part]));
    }
}
