import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'vi-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
