import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private auth: AuthService) {}

    canActivate(route?: ActivatedRouteSnapshot): Observable<boolean> {
        if (this.auth.hasValidCsrfToken()) {
            return of(true);
        }
        // start csrf token authentication process
        return this.auth.fetchNewCsrfToken().pipe(map((res) => !!res.token));
    }
}
