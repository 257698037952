import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, share, tap } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private CSRF_TOKEN_STORAGE_KEY = 'fp_csrf_token';

    public csrfToken$ = new BehaviorSubject(undefined);
    private fetchNewCsrfToken$: Observable<{ token: string }>;

    private httpClient: HttpClient;

    constructor(handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);
    }

    public hasValidCsrfToken(): boolean {
        const token = this.getCsrfToken();
        return token && this.isCsrfTokenValid(token);
    }

    public getCsrfToken(): string {
        return this.csrfToken$.value;
    }

    init() {
        this.csrfToken$.subscribe((jwtToken) => {
            if (jwtToken) {
                localStorage.setItem(this.CSRF_TOKEN_STORAGE_KEY, jwtToken);
            }
        });

        this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
            tap({
                error: () => this.redirectToLoginScreen(),
            }),
            share()
        );

        const token = this.getCsrfTokenFromUrl() || this.getCsrfTokenFromLocalStorage();

        if (token && this.isCsrfTokenValid(token)) {
            this.csrfToken$.next(token);
        } else {
            this.removeTokenFromLocalStorage();
            this.csrfToken$.next(null);
        }
    }

    removeTokenFromLocalStorage() {
        localStorage.removeItem(this.CSRF_TOKEN_STORAGE_KEY);
    }

    onLogout() {
        this.removeTokenFromLocalStorage();
    }

    fetchNewCsrfToken() {
        return this.fetchNewCsrfToken$;
    }

    public getCsrfTokenRequest() {
        return this.httpClient
            .post<{ token: string }>(
                `${environment.auth.baseUrl}/auth/v1/saml/csrf`,
                {
                    appId: environment.auth.appId,
                },
                {
                    withCredentials: true,
                }
            )
            .pipe(
                map((resp) => {
                    this.csrfToken$.next(resp.token);
                    return resp;
                })
            );
    }

    private getCsrfTokenFromLocalStorage(): string | undefined {
        return localStorage.getItem(this.CSRF_TOKEN_STORAGE_KEY);
    }

    private getCsrfTokenFromUrl(): string | undefined {
        const hash = window.location.hash;
        if (hash && hash.startsWith('#token=')) {
            const value = new HttpParams({ fromString: hash }).get('#token');
            window.location.hash = ''; // remove token form url
            return value;
        }
    }

    private redirectToLoginScreen() {
        window.location.href = `${environment.auth.baseUrl}/saml/sso/request?appId=${environment.auth.appId}`;
    }

    private isCsrfTokenValid(token: string) {
        try {
            return !this.isCSRFTokenExpired(token);
        } catch (_) {
            return false;
        }
    }

    isCSRFTokenExpired(token: string): boolean {
        const date = this.getTokenExpirationDate(token);
        if (!date) {
            return false;
        }
        return !(date.valueOf() > new Date().valueOf());
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }
}
