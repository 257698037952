import { ApiBase } from './api.base';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from '../user/auth.service';
import { catchError, switchMap } from 'rxjs/operators';

/**
 * Base class for api services that require authentication.
 */
@Injectable()
export class ApiAuthenticated extends ApiBase {
    constructor(http: HttpClient, translate: TranslateService, handler: HttpBackend, auth: AuthService) {
        super(http, translate, handler, auth);
    }

    request(method: string, url: string, data?: any, options?: any): Observable<any> {
        if (this.auth.hasValidCsrfToken()) {
            const headers = {
                Authorization: `CSRF ${this.auth.getCsrfToken()}`,
            };
            return super.request(method, url, data, { headers, ...options }).pipe(
                catchError((e) => {
                    if (e instanceof HttpErrorResponse && e.status === 401) {
                        // edge case that can happen if token validation says its valid but actually it is not on backend side
                        return this.retryOnTokenExpired(method, url, data, options);
                    }
                    throw e;
                })
            );
        } else {
            return this.retryOnTokenExpired(method, url, data, options);
        }
    }

    retryOnTokenExpired(method: string, url: string, data?: any, options?: any) {
        return this.auth.fetchNewCsrfToken().pipe(switchMap(() => this.request(method, url, data, options)));
    }
}
