import { Component, Input, OnInit } from '@angular/core';

/**
 * headline component
 * showing landing page image and headline
 *
 * @param key translation key for title
 */
@Component({
    selector: 'vi-headline',
    templateUrl: './headline.component.html',
    styleUrls: ['./headline.component.scss'],
})
export class HeadlineComponent implements OnInit {
    @Input() key: string;

    @Input() subtitleKey: string;

    constructor() {}

    ngOnInit() {}
}
