import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';

@Injectable()
export class AppConfig {
    /**
     * Defines group to be recognized as landing page - renders only that group with wizard display
     */
    landingGroup: string = environment.app.landingGroup;

    /**
     * Defines group to be recognized as dropout page
     */
    dropoutGroup: string = environment.quest.dropoutGroup;

    /**
     * Defines group to be recognized as recommend page
     */
    recommendGroup: string = environment.quest.recommendGroup;

    /**
     * Defines the variable that holds the price for the order
     */
    orderPriceVariable: string = environment.quest.orderPriceVariable;

    /**
     * Defines link to be used on dropout page
     */
    dropoutLink: string = environment.app.dropoutLink;

    /**
     * Logo file
     */
    logo: string = environment.app.logo;

    /**
     * Defines the page title translation key
     */
    pageTitle: string = environment.app.pageTitle;

    /**
     * Defines the page description translation key
     */
    pageDescription: string = environment.app.pageDescription;

    /**
     * Defines the menu items for main (header navigation and left and right side navigation
     */
    menu: any = environment.menu;

    /**
     * Configit material name - quest
     */
    quest: string = environment.app.quest;

    /**
     * array of icons to be registered
     */
    icons: any = environment.icons;

    /**
     * date format for use in angular date pipe
     */
    dateFormat = 'dd.MM.yyyy HH:mm';

    isfp = {
        id: 'LandingPage.BasicData.System',
        value: 'isfp',
    };
}
