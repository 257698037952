import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

/**
 * Service providing reporting tracked events.
 *
 * @example
 * ```ts
 * import { TrackingService } from '../../utils/tracking.service';
 * // ...
 * constructor(private tracking: TrackingService) {}
 * // ...
 * some() {
 *   this.tracking.event('action', 'category', 'label');
 * }
 */
@Injectable()
export class TrackingService {
    constructor(
        private track: Angulartics2,
        private analytics: Angulartics2GoogleAnalytics,
        private tags: Angulartics2GoogleTagManager
    ) {
        this.analytics.startTracking();
        this.tags.startTracking();
    }

    /**
     * Fires event track
     *
     * @param action
     * @param category
     * @param label
     */
    event(action: string, category?: any, label?: string): void {
        const properties = { category, label };

        this.track.eventTrack.next({ action, properties });
    }
}
