import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';

@Component({
    selector: 'vi-navigation-item',
    templateUrl: './navigation-item.component.html',
    styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent implements OnInit {
    @Input() item: NavigationItem;
    @Input() campaign: string;

    useMaterialIcons: boolean;

    constructor() {}

    ngOnInit() {
        if (!this.campaign) {
            this.campaign = '';
        }
        if (this.item.icon && !this.item.icon.includes('subc')) {
            this.useMaterialIcons = true;
        }
    }
}
