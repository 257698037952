import { Component, OnInit } from '@angular/core';
import { downloads } from './downloads';

@Component({
    selector: 'vi-downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit {
    downloads = downloads;
    constructor() {}

    ngOnInit() {}
}
