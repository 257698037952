import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Angulartics2Module, Angulartics2Settings } from 'angulartics2';

import { UIComponentsModule } from '@vi-iot/ui';

import { environment } from '../environments/environment';
import { TrackingService } from './utils/tracking.service';

import { AppConfig } from './app.config';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppDateAdapter } from './app.date-adapter';
import { HeaderComponent } from './components/header/header.component';
import { NavigationSideComponent } from './components/navigation/navigation-side.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationItemComponent } from './components/navigation/navigation-item.component';
import { InViewDirective } from './utils/in-view.directive';
import { RedirectHomeDirective } from './utils/redirect-home.directive';

import { MatListModule } from '@angular/material/list';
import { GlobalHeaderModule } from '@vi/global-header';
import { SharedModule } from './modules/shared/shared.module';
import { AssignOrderComponent } from './components/+assign-order/assign-order.component';
import { MaintenanceMessageComponent } from './components/maintenance/maintenance-message/maintenance-message';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MatPaginatorIntlDe } from './CustomMatPaginatorIntl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DownloadsComponent } from './modules/downloads/downloads.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ContactComponent } from './modules/contact/contact.component';
import { ErrorHandler } from '@angular/core';
import { InstanaErrorHandler } from './instana-error-handler';
import { InfoBannerComponent } from './components/info-banner/info-banner.component';

// ng-translate loader
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.http.translations, '/download?file_format=i18next');
}

const translateConfig: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
    },
};

// analytics config
const angularticsSettings: Partial<Angulartics2Settings> = {
    pageTracking: {
        clearIds: true,
        idsRegExp: new RegExp(environment.analytics.anonymizeParams),
    },
    developerMode: !environment.production,
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        NavigationSideComponent,
        NavigationComponent,
        FooterComponent,
        NavigationItemComponent,
        InViewDirective,
        RedirectHomeDirective,
        AssignOrderComponent,
        MaintenanceMessageComponent,
        MaintenanceComponent,
        DownloadsComponent,
        NotfoundComponent,
        ContactComponent,
        InfoBannerComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CommonModule,
        UIComponentsModule,
        GlobalHeaderModule.forRoot({
            appId: environment.auth.appId,
            baseUrl: environment.auth.baseUrl,
            accountAppUrl: environment.auth.registerBaseUrl,
            loginRedirectUrl: environment.auth.loginRedirectUrl,
            logoutRedirectUrl: true,
            showRegisterLink: false,
        }),
        SharedModule,
        HttpClientModule,
        MatIconModule,
        MatSnackBarModule,
        MatListModule,
        Angulartics2Module.forRoot(angularticsSettings),
        TranslateModule.forRoot(translateConfig),
    ],
    providers: [
        AppConfig,
        TrackingService,
        { provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: environment.dateFormat },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
        {
            provide: ErrorHandler,
            useClass: InstanaErrorHandler,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
