import { Component, OnInit } from '@angular/core';
import { PlannedMaintenance, StatusPageService } from '../../../services/status-page.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'vi-maintenance-message',
    templateUrl: './maintenance-message.html',
    styleUrls: ['./maintenance-message.scss'],
})
export class MaintenanceMessageComponent implements OnInit {
    public visible = false;
    public maintenance: PlannedMaintenance;
    public partialOutage: boolean;

    constructor(private statusPage: StatusPageService) {}

    public ngOnInit() {
        this.statusPage
            .getStatus()
            .pipe(filter((s) => !!s))
            .subscribe((status) => {
                this.partialOutage = ['partial_outage', 'degraded_performance'].includes(status.status);
                this.maintenance = status.next_maintenance;
                this.visible = this.partialOutage || !!this.maintenance;
            });
    }
}
